import { Box, Step, StepIcon, StepIndicator, StepSeparator, StepStatus, StepTitle, Stepper, Text } from '@chakra-ui/react';
import { ProgressStepModel } from '../../lib/WizardGenerator/models/WizardDefinition';
import { useSearchParams } from 'react-router-dom';

type WizardProgressProps = {
  currentStep: number;
  wizardSteps: Array<ProgressStepModel>;
};

const WizardProgress = ({ currentStep, wizardSteps }: WizardProgressProps) => {
  const [searchParams] = useSearchParams();
  const isHeaderless = !!searchParams.get('headerless');
  return (
    <Box backgroundColor={ '#F9F9F9' } margin={'auto'} w={'100%'} pos={'absolute'} top={isHeaderless ? '10px' : ['65px', '65px', '140px']} left={0} right={0} zIndex={499}>
      <Stepper colorScheme='green' index={currentStep} paddingTop={'10px'} w={['95%', '95%', '95%', '65%']} margin={'auto'} size={['sm', 'lg']}>
        {wizardSteps.map((step, index) => {
          return (
            <Step key={index}>
              <>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} />
                </StepIndicator>

                <Box flexShrink='0' hideBelow={'2xl'}>
                  <StepTitle>
                    <Text fontSize={{ base: 'small', '2xl': 'sm' }}>{step.Name}</Text>
                  </StepTitle>
                </Box>

                <StepSeparator />
              </>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default WizardProgress;
