import { ChakraProvider, createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ButtonTheme } from '../theme/button';
import { FormLabelTheme } from '../theme/formLabel';
import { StepperTheme } from '../theme/stepper';
import { queryClient } from './lib/queryClient';
import { AppRoutes } from './routes';
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)


const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    backgroundColor:'white'
  },
})

export const inputTheme =defineMultiStyleConfig({ baseStyle });
// Version 2: Using functions
const overrides = extendTheme({
  components: {
    Button: ButtonTheme,
    Stepper: StepperTheme,
    FormLabel: FormLabelTheme,
    Input: inputTheme,
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'blue.100'
        }
      }
    }
  },
  fonts: {
    heading: `Montserrat`,
    body: `Montserrat`
  },
  colors: {
    purple: {
      100: 'rgb(201, 77, 157)',
      200: 'rgb(201, 77, 157)',
      300: 'rgb(201, 77, 157)',
      400: 'rgb(201, 77, 157)',
      500: 'rgb(201, 77, 157)',
      600: 'rgb(201, 77, 157)',
      700: 'rgb(201, 77, 157)',
      800: 'rgb(201, 77, 157)',
      900: 'rgb(201, 77, 157)',
      50: 'rgba(201, 77, 157, 0.5)',
      25: 'rgba(201, 77, 157, 0.25)',
      15: 'rgba(201, 77, 157, 0.15)'
    },
    blue: {
      100: 'rgba(90, 183, 232, 1)',
      50: 'rgba(90, 183, 232, 0.5)',
      25: 'rgba(90, 183, 232, 0.25)',
      15: 'rgba(90, 183, 232, 0.15)'
    },
    green: {
      100: 'rgb(115,199,167)',
      200: 'rgb(115,199,167)',
      300: 'rgb(115,199,167)',
      400: 'rgb(115,199,167)',
      500: 'rgb(115,199,167)',
      600: 'rgb(115,199,167)',
      700: 'rgb(115,199,167)',
      800: 'rgb(115,199,167)',
      900: 'rgb(115,199,167)',
      50: 'rgb(115,199,167, 0.5)',
      25: 'rgb(115,199,167, 0.25)',
      15: 'rgb(115,199,167, 0.15)'
    }
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        background:'#F9F9F9F9'
      }
    }
  }
});
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={overrides} cssVarsRoot='body'>
        <AppRoutes />
      </ChakraProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
