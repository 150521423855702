import { Box, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { AddressSelector } from '../../components/forms/AddressSelector';
import { BooleanRadio } from '../../components/forms/BooleanRadio';

import React from 'react';
import { DateSelector } from '../../components/forms/DateSelector';
import { FormHelpText } from '../../components/forms/FormHelpText';
import { IconCheckboxField } from '../../components/forms/IconInputs/IconCheckboxField';
import { IconQuantityField } from '../../components/forms/IconInputs/IconQuantityField';
import { IconRadioField } from '../../components/forms/IconInputs/IconRadioField';
import { NumericField } from '../../components/forms/NumericField';
import { SelectField } from '../../components/forms/SelectField';
import { TextField } from '../../components/forms/TextField';
import { HiddenField } from '../../components/forms/hiddenField';
import { getValidationResults } from '../../components/forms/utils';
import { PropertyDisplayType } from '../../enums/form/PropertyDisplayType';
import { EntityPropertyModel } from '../EntityDesigner/models/EntityPropertyModel';
import { PropertyType } from '../EntityDesigner/models/PropertyType';
import { AddressSelectorConfig, IconCheckboxConfig, IconQuantityConfig, IconRadioConfig, WizardQuestion } from './models/WizardDefinition';

type QuestionRendererProps = {
  wizardQuestion: WizardQuestion;
  entityPropertyModels: EntityPropertyModel[];
};

const inputFieldPadding = '20px';

const renderSwitch = (propertyModel: EntityPropertyModel, wizardModel: WizardQuestion) => {
  if (wizardModel.PropertyDisplayType) {
    switch (wizardModel.PropertyDisplayType) {
      case PropertyDisplayType.AddressSelector:
        return (
          <Box paddingBottom={inputFieldPadding}>
            <AddressSelector label={wizardModel.Label} name={propertyModel.key} config={wizardModel.PropertyDisplayTypeConfig as AddressSelectorConfig} />
          </Box>
        );
      case PropertyDisplayType.Hidden:
        return <HiddenField name={propertyModel.key} />;
      case PropertyDisplayType.IconRadio:
        return <IconRadioField label={wizardModel.Label} name={propertyModel.key} config={wizardModel.PropertyDisplayTypeConfig as IconRadioConfig} />;
      case PropertyDisplayType.IconQuantity:
        return <IconQuantityField label={wizardModel.Label} name={propertyModel.key} config={wizardModel.PropertyDisplayTypeConfig as IconQuantityConfig} />;
      case PropertyDisplayType.IconCheckbox:
        return <IconCheckboxField label={wizardModel.Label} name={propertyModel.key} config={wizardModel.PropertyDisplayTypeConfig as IconCheckboxConfig} />;
      case PropertyDisplayType.DatePicker:
        return (
          <Box paddingBottom={inputFieldPadding}>
            {' '}
            <DateSelector label={wizardModel.Label} name={propertyModel.key} />{' '}
          </Box>
        );
    }
  }
  switch (propertyModel.type) {
    case PropertyType.Boolean:
      return (
        <Box paddingBottom={inputFieldPadding}>
          <BooleanRadio label={wizardModel.Label} name={propertyModel.key} />
        </Box>
      );
    case PropertyType.String:
      return (
        <Box paddingBottom={inputFieldPadding}>
          <TextField label={wizardModel.Label} name={propertyModel.key} mask={wizardModel.Mask} />
        </Box>
      );
    case PropertyType.Number:
      return (
        <Box paddingBottom={inputFieldPadding}>
          <NumericField label={wizardModel.Label} name={propertyModel.key} min={wizardModel.NumericMin} max={wizardModel.NumericMax} step={wizardModel.NumericStep} isNumeric={true} />
        </Box>
      );
    case PropertyType.Enum:
      return (
        <Box paddingBottom={inputFieldPadding}>
          <SelectField items={propertyModel.propertyListValuesModels} label={wizardModel.Label} name={propertyModel.key} />
        </Box>
      );
  }
};

const innerRender = (propertyModel: EntityPropertyModel, allPropertyModels: EntityPropertyModel[], wizardModel: WizardQuestion) => {
  if (wizardModel.CustomRenderer) {
    return <wizardModel.CustomRenderer wizardModel={wizardModel} propertyModel={propertyModel} />;
  } else if (wizardModel.RenderWith) {
    return (
      <Flex gap={4} flexWrap={'wrap'}>
        {renderSwitch(propertyModel, wizardModel)}

        {wizardModel.RenderWith.map((value, key) => {
          const propertyDef = allPropertyModels.find((prop) => prop.key == value.Key);
          if (propertyDef === undefined) {
            return <div key={key}>Not found!</div>;
          }
          return <React.Fragment key={key}>{renderSwitch(propertyDef, value)}</React.Fragment>;
        })}
      </Flex>
    );
  } else {
    return renderSwitch(propertyModel, wizardModel);
  }
};

export const QuestionRenderer = (props: QuestionRendererProps) => {
  const [hideQuestion, setHideQuestion] = useState<boolean>(false);
  const methods = useFormContext();
  const hasConsented = useWatch({
    name: 'Contact.EmailConsent',
    control: methods.control
  })

  const propertyDef = props.entityPropertyModels.find((prop) => prop.key == props.wizardQuestion.Key);

  if (propertyDef.key == 'Contact.Email' && hasConsented == undefined){
    methods.setValue('Contact.EmailConsent', false);
  }

  if (propertyDef === undefined) {
    return <div>Not found!</div>;
  }

  const hideIfWatch = methods.watch(props.wizardQuestion.HideIfProperty!);

  const result = getValidationResults(props.wizardQuestion.Key, methods);

  useEffect(() => {
    if (props.wizardQuestion.HideIfProperty && props.wizardQuestion.HideIfValue) {
      const value = methods.getValues(props.wizardQuestion.HideIfProperty);
      const isInValueArray = props.wizardQuestion.HideIfValue.find((arrayValue) => {
        return value == arrayValue;
      });
      setHideQuestion(isInValueArray !== undefined || value === undefined);
    }
  }, [hideIfWatch]);



  if (hideQuestion) {
    return null;
  }

  return (
    <FormControl id={propertyDef.name} isInvalid={result}>
      <HStack>
        {props.wizardQuestion.Label && (
          <FormLabel>
            {props.wizardQuestion.Label} {props.wizardQuestion.HelpText && <FormHelpText text={props.wizardQuestion.HelpText} />}
          </FormLabel>
        )}
      </HStack>
      {innerRender(propertyDef, props.entityPropertyModels, props.wizardQuestion)}
      {propertyDef.key == 'Contact.Email' && <Checkbox isChecked={hasConsented} onChange={(e)=>{
        methods.setValue('Contact.EmailConsent', e.target.checked)
      }} marginTop={'-15px'} paddingBottom={'5px'} >Yes, please send me the best cleaning tips, upcoming promotions, and monthly giveaways from MopSpot.</Checkbox>}
      <FormErrorMessage paddingBottom={'5px'} marginTop={'-15px'}>
        {result?.message?.toString()}
      </FormErrorMessage>


    </FormControl>
  );
};
